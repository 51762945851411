<script>
import {
  required, email, minLength, maxLength,
} from 'vuelidate/lib/validators';
import { isProd } from '@shared/env';
import { notInTempEmails, /* inArray, */ isValue } from '@shared/config/vuelidate';
import emailList from '@shared/assets/data/temp_emails.json';

export default {
  data() {
    return {
      user: {
        firstname: '',
        lastname: '',
        token: this.$route.query.t,
        email: this.$route.query.email,
        password: '',
        accept_email_marketing: false,
        // plan: this.$route.query.pro ? 'PRO' : 'PERCENT',
      },
      accept: false,
      isLoading: false,
    };
  },
  validations: {
    user: {
      firstname: {
        required,
        maxLength: maxLength(128),
      },
      lastname: {
        required,
        maxLength: maxLength(128),
      },
      email: {
        required,
        email,
        notInTempEmails: notInTempEmails(isProd ? emailList : []),
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(100),
      },
      // plan: {
      //   required,
      //   inArray: inArray(['PRO', 'PERCENT']),
      // },
    },
    accept: {
      isValue: isValue(true),
    },
  },
  methods: {
    handle() {
      this.isLoading = true;
      this.$store.dispatch('auth/register', this.user)
        .then(() => {
          const d = document.createElement('iframe');
          d.style.width = '0';
          d.style.height = '0';
          d.style.border = '0';
          d.style.overflow = 'hidden';
          d.src = `https://www.${this.$env.rootHost}/${(this.$isProd ? 'thank-you-signup' : 'toto')}/`;
          document.body.appendChild(d);
        })
        .catch((error) => this.$errorHandlers.axios(error))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <section class="form p-5">
    <form novalidate @submit.prevent="dataIsValid(isLoading) && handle()">
      <h1 class="title is-4">
        Inscription
      </h1>

      <div class="columns is-multiline">
        <b-field
          class="column mb-0 pb-0 is-6"
          label="Prénom"
          v-bind="$getErrorProps($v.user.firstname, ['required', { name: 'maxLength', args: [128] }])"
        >
          <b-input v-model="user.firstname" />
        </b-field>
        <b-field
          class="column mb-0 pb-0 is-6"
          label="Nom"
          v-bind="$getErrorProps($v.user.lastname, ['required', { name: 'maxLength', args: [128] }])"
        >
          <b-input v-model="user.lastname" />
        </b-field>

        <b-field
          class="column mb-0 pb-0 is-12"
          label="Adresse email"
          v-bind="$getErrorProps($v.user.email, ['required', 'email', 'notInTempEmails'])"
        >
          <b-input v-model="user.email" type="email" :disabled="$route.query.email" />
        </b-field>

        <b-field
          class="column mb-0 pb-0 is-12"
          label="Mot de passe"
          v-bind="$getErrorProps($v.user.password, [
            'required',
            { name: 'minLength', args: [8] },
            { name: 'maxLength', args: [100] },
          ])"
        >
          <b-input v-model="user.password" type="password" placeholder="8 caractères minimum" password-reveal />
        </b-field>

        <!-- <b-field
          class="column mb-0 pb-0 is-12"
          v-bind="$getErrorProps($v.user.plan, ['required', 'inArray'])"
        >
          <b-switch v-model="user.plan" type="is-success" size="is-small" true-value="PRO" false-value="PERCENT">
            Prendre le Pack PRO
          </b-switch>
        </b-field> -->

        <b-field
          class="column mb-0 pb-0 is-12"
          v-bind="$getErrorProps($v.accept, [{ name: 'isValue', message: 'Vous devez accepter nos conditions' }])"
        >
          <b-checkbox v-model="accept" size="is-small">
            J'accepte les
            <BaseShowCmsPage class="tdecoration-underline" page="cgv">
              conditions générales
            </BaseShowCmsPage>
            et la
            <BaseShowCmsPage class="tdecoration-underline" page="confidentialite">
              politique de confidentialité
            </BaseShowCmsPage>
            de Teachizy
          </b-checkbox>
        </b-field>
        <div class="column">
          <div class="px-10 py-5 my-3 is-12 -mx-10 has-background-primary-light">
            <b-checkbox class="is-primary" v-model="user.accept_email_marketing" size="is-small">
              <strong>📩 La Lettre Teachizy</strong>
              <br>
              Recevoir tous les mois un concentré d'astuces, d'outils et d'actus pour mieux former en ligne !
            </b-checkbox>
          </div>
        </div>

        <b-field class="column mb-0 pb-0 is-12">
          <b-button type="is-primary" native-type="submit" :loading="isLoading" expanded>
            S'inscrire
          </b-button>
        </b-field>
      </div>
    </form>

    <hr>

    <p>
      Déjà un compte ?
      <router-link class="tdecoration-underline" to="/connexion">
        Connectez-vous
      </router-link>
    </p>
  </section>
</template>
